.newsblock {
  padding-top: em(120, $fz);

  &__photo {
    display: block;
    padding-bottom: em(25, $fz);
  }

  &__desc {
    line-height: em(27, $fz);
    margin-bottom: em(65, $fz);
  }

  &__graph {
    margin-bottom: em(40, $fz);
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    margin-bottom: 40px;
  }
}

.newsblock-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: em(90, $fz);
  }

  &__icon {
    width: em(27, $fz);
    height: em(27, $fz);
    background-color: #473d6b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: em(10, $fz);
  }

  &__svg {
    fill: #fff;
    width: em(17, $fz);
    height: em(17, $fz);
  }
}

.opinion {
  border-left: em(6, $fz) solid #0e6ad5;
  padding: em(22, $fz) 1px em(32, $fz) em(28, $fz);
  margin-bottom: em(37, $fz);

  &__desc {
    color: #2d1e4e;
    line-height: em(27, $fz);
    margin-bottom: em(25, $fz);
  }
}

.opinion-author {
  position: relative;
  display: flex;
  align-items: center;

  &__photo {
    margin-right: em(23, $fz);
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: 700;
    color: $text-color;
    margin-bottom: em(12, $fz);
  }

  &__post {
    color: $text-color;
  }

}

.tags {
  display: inline-block;

  &__title {
    color: $text-color;
    margin-right: em(30, $fz);
  }

  &__item {
    color: #fff;
    padding: em(4, $fz) em(14, $fz);
    background-color: #473d6b;
    margin-right: em(30, $fz);

    &:hover {
      background-color: $main-color;
    }
  }
}

.newsblock__networks {
  .networks__link {
    margin-right: em(29, $fz);

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: $media-xl) {
}

@media only screen and (max-width: $media-l) {
  .newsblock {
    padding-top: em(100, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .newsblock {
    padding-top: em(80, $fz);
  }

  .newsblock__desc {
    margin-bottom: em(45, $fz);
  }

  .newsblock__info {
    margin-bottom: em(30, $fz);
  }

  .newsblock-info__item {
    margin-right: em(25, $fz);
  }
}

@media only screen and (max-width: $media-s) {
  .newsblock {
    padding-top: em(60, $fz);
  }

  .newsblock__bottom {
    flex-direction: column;
    align-items: center;
  }

  .tags {
    margin-bottom: em(10, $fz);
  }
}

@media only screen and (max-width: $media-xs) {
  .newsblock {
    padding-top: em(50, $fz);
  }

  .newsblock__info {
    margin-bottom: em(20, $fz);
  }

  .newsblock-info {
    justify-content: flex-start;
  }

  .newsblock-info__item {
    margin-right: em(20, $fz);
    margin-bottom: em(5, $fz);
  }

  .newsblock__desc {
    margin-bottom: em(25, $fz);
  }

  .opinion {
    padding: em(15, $fz) 1px em(20, $fz) em(15, $fz);
  }

  .tags__title {
    margin-right: em(10, $fz);
  }

  .tags__item {
    margin-right: em(20, $fz);
  }
}
