.sprite {
  background-image: url('../img/flags.png');
  background-repeat: no-repeat;
  display: block;
  margin-right: 7px;
}

.sprite-ad {
  width: 24px;
  height: 24px;
  background-position: -5px -5px;
}

.sprite-ae {
  width: 24px;
  height: 24px;
  background-position: -39px -5px;
}

.sprite-af {
  width: 24px;
  height: 24px;
  background-position: -73px -5px;
}

.sprite-ag {
  width: 24px;
  height: 24px;
  background-position: -107px -5px;
}

.sprite-ai {
  width: 24px;
  height: 24px;
  background-position: -141px -5px;
}

.sprite-al {
  width: 24px;
  height: 24px;
  background-position: -175px -5px;
}

.sprite-am {
  width: 24px;
  height: 24px;
  background-position: -209px -5px;
}

.sprite-an {
  width: 24px;
  height: 24px;
  background-position: -243px -5px;
}

.sprite-ao {
  width: 24px;
  height: 24px;
  background-position: -277px -5px;
}

.sprite-aq {
  width: 24px;
  height: 24px;
  background-position: -311px -5px;
}

.sprite-ar {
  width: 24px;
  height: 24px;
  background-position: -345px -5px;
}

.sprite-as {
  width: 24px;
  height: 24px;
  background-position: -379px -5px;
}

.sprite-at {
  width: 24px;
  height: 24px;
  background-position: -413px -5px;
}

.sprite-au {
  width: 24px;
  height: 24px;
  background-position: -447px -5px;
}

.sprite-aw {
  width: 24px;
  height: 24px;
  background-position: -481px -5px;
}

.sprite-ax {
  width: 24px;
  height: 24px;
  background-position: -5px -39px;
}

.sprite-az {
  width: 24px;
  height: 24px;
  background-position: -39px -39px;
}

.sprite-ba {
  width: 24px;
  height: 24px;
  background-position: -73px -39px;
}

.sprite-bat {
  width: 24px;
  height: 24px;
  background-position: -107px -39px;
}

.sprite-bb {
  width: 24px;
  height: 24px;
  background-position: -141px -39px;
}

.sprite-bd {
  width: 24px;
  height: 24px;
  background-position: -175px -39px;
}

.sprite-be {
  width: 24px;
  height: 24px;
  background-position: -209px -39px;
}

.sprite-bf {
  width: 24px;
  height: 24px;
  background-position: -243px -39px;
}

.sprite-bg {
  width: 24px;
  height: 24px;
  background-position: -277px -39px;
}

.sprite-bh {
  width: 24px;
  height: 24px;
  background-position: -311px -39px;
}

.sprite-bi {
  width: 24px;
  height: 24px;
  background-position: -345px -39px;
}

.sprite-bj {
  width: 24px;
  height: 24px;
  background-position: -379px -39px;
}

.sprite-bl {
  width: 24px;
  height: 24px;
  background-position: -413px -39px;
}

.sprite-bm {
  width: 24px;
  height: 24px;
  background-position: -447px -39px;
}

.sprite-bn {
  width: 24px;
  height: 24px;
  background-position: -481px -39px;
}

.sprite-bo {
  width: 24px;
  height: 24px;
  background-position: -5px -73px;
}

.sprite-bq {
  width: 24px;
  height: 24px;
  background-position: -39px -73px;
}

.sprite-br {
  width: 24px;
  height: 24px;
  background-position: -73px -73px;
}

.sprite-bs {
  width: 24px;
  height: 24px;
  background-position: -107px -73px;
}

.sprite-bt {
  width: 24px;
  height: 24px;
  background-position: -141px -73px;
}

.sprite-bw {
  width: 24px;
  height: 24px;
  background-position: -175px -73px;
}

.sprite-by {
  width: 24px;
  height: 24px;
  background-position: -209px -73px;
}

.sprite-bz {
  width: 24px;
  height: 24px;
  background-position: -243px -73px;
}

.sprite-ca {
  width: 24px;
  height: 24px;
  background-position: -277px -73px;
}

.sprite-cascadia {
  width: 24px;
  height: 24px;
  background-position: -311px -73px;
}

.sprite-cc {
  width: 24px;
  height: 24px;
  background-position: -345px -73px;
}

.sprite-cd {
  width: 24px;
  height: 24px;
  background-position: -379px -73px;
}

.sprite-cf {
  width: 24px;
  height: 24px;
  background-position: -413px -73px;
}

.sprite-cg {
  width: 24px;
  height: 24px;
  background-position: -447px -73px;
}

.sprite-ch {
  width: 24px;
  height: 24px;
  background-position: -481px -73px;
}

.sprite-ci {
  width: 24px;
  height: 24px;
  background-position: -5px -107px;
}

.sprite-ck {
  width: 24px;
  height: 24px;
  background-position: -39px -107px;
}

.sprite-cl {
  width: 24px;
  height: 24px;
  background-position: -73px -107px;
}

.sprite-cm {
  width: 24px;
  height: 24px;
  background-position: -107px -107px;
}

.sprite-cn {
  width: 24px;
  height: 24px;
  background-position: -141px -107px;
}

.sprite-co {
  width: 24px;
  height: 24px;
  background-position: -175px -107px;
}

.sprite-cr {
  width: 24px;
  height: 24px;
  background-position: -209px -107px;
}

.sprite-cu {
  width: 24px;
  height: 24px;
  background-position: -243px -107px;
}

.sprite-cv {
  width: 24px;
  height: 24px;
  background-position: -277px -107px;
}

.sprite-cw {
  width: 24px;
  height: 24px;
  background-position: -311px -107px;
}

.sprite-cx {
  width: 24px;
  height: 24px;
  background-position: -345px -107px;
}

.sprite-cy {
  width: 24px;
  height: 24px;
  background-position: -379px -107px;
}

.sprite-cz {
  width: 24px;
  height: 24px;
  background-position: -413px -107px;
}

.sprite-de {
  width: 24px;
  height: 24px;
  background-position: -447px -107px;
}

.sprite-dj {
  width: 24px;
  height: 24px;
  background-position: -481px -107px;
}

.sprite-dk {
  width: 24px;
  height: 24px;
  background-position: -5px -141px;
}

.sprite-do {
  width: 24px;
  height: 24px;
  background-position: -39px -141px;
}

.sprite-dz {
  width: 24px;
  height: 24px;
  background-position: -73px -141px;
}

.sprite-ec {
  width: 24px;
  height: 24px;
  background-position: -107px -141px;
}

.sprite-ee {
  width: 24px;
  height: 24px;
  background-position: -141px -141px;
}

.sprite-eg {
  width: 24px;
  height: 24px;
  background-position: -175px -141px;
}

.sprite-england {
  width: 24px;
  height: 24px;
  background-position: -209px -141px;
}

.sprite-er {
  width: 24px;
  height: 24px;
  background-position: -243px -141px;
}

.sprite-es {
  width: 24px;
  height: 24px;
  background-position: -277px -141px;
}

.sprite-et {
  width: 24px;
  height: 24px;
  background-position: -311px -141px;
}

.sprite-eu {
  width: 24px;
  height: 24px;
  background-position: -345px -141px;
}

.sprite-fi {
  width: 24px;
  height: 24px;
  background-position: -379px -141px;
}

.sprite-fj {
  width: 24px;
  height: 24px;
  background-position: -413px -141px;
}

.sprite-fk {
  width: 24px;
  height: 24px;
  background-position: -447px -141px;
}

.sprite-fm {
  width: 24px;
  height: 24px;
  background-position: -481px -141px;
}

.sprite-fo {
  width: 24px;
  height: 24px;
  background-position: -5px -175px;
}

.sprite-fp {
  width: 24px;
  height: 24px;
  background-position: -39px -175px;
}

.sprite-fr {
  width: 24px;
  height: 24px;
  background-position: -73px -175px;
}

.sprite-ga {
  width: 24px;
  height: 24px;
  background-position: -107px -175px;
}

.sprite-gd {
  width: 24px;
  height: 24px;
  background-position: -141px -175px;
}

.sprite-ge {
  width: 24px;
  height: 24px;
  background-position: -175px -175px;
}

.sprite-gg {
  width: 24px;
  height: 24px;
  background-position: -209px -175px;
}

.sprite-gh {
  width: 24px;
  height: 24px;
  background-position: -243px -175px;
}

.sprite-gi {
  width: 24px;
  height: 24px;
  background-position: -277px -175px;
}

.sprite-gl {
  width: 24px;
  height: 24px;
  background-position: -311px -175px;
}

.sprite-gm {
  width: 24px;
  height: 24px;
  background-position: -345px -175px;
}

.sprite-gn {
  width: 24px;
  height: 24px;
  background-position: -379px -175px;
}

.sprite-gp {
  width: 24px;
  height: 24px;
  background-position: -413px -175px;
}

.sprite-gq {
  width: 24px;
  height: 24px;
  background-position: -447px -175px;
}

.sprite-gr {
  width: 24px;
  height: 24px;
  background-position: -481px -175px;
}

.sprite-gs {
  width: 24px;
  height: 24px;
  background-position: -5px -209px;
}

.sprite-gt {
  width: 24px;
  height: 24px;
  background-position: -39px -209px;
}

.sprite-gu {
  width: 24px;
  height: 24px;
  background-position: -73px -209px;
}

.sprite-gw {
  width: 24px;
  height: 24px;
  background-position: -107px -209px;
}

.sprite-gy {
  width: 24px;
  height: 24px;
  background-position: -141px -209px;
}

.sprite-hk {
  width: 24px;
  height: 24px;
  background-position: -175px -209px;
}

.sprite-hn {
  width: 24px;
  height: 24px;
  background-position: -209px -209px;
}

.sprite-hr {
  width: 24px;
  height: 24px;
  background-position: -243px -209px;
}

.sprite-ht {
  width: 24px;
  height: 24px;
  background-position: -277px -209px;
}

.sprite-hu {
  width: 24px;
  height: 24px;
  background-position: -311px -209px;
}

.sprite-id {
  width: 24px;
  height: 24px;
  background-position: -345px -209px;
}

.sprite-ie {
  width: 24px;
  height: 24px;
  background-position: -379px -209px;
}

.sprite-il {
  width: 24px;
  height: 24px;
  background-position: -413px -209px;
}

.sprite-im {
  width: 24px;
  height: 24px;
  background-position: -447px -209px;
}

.sprite-in {
  width: 24px;
  height: 24px;
  background-position: -481px -209px;
}

.sprite-io {
  width: 24px;
  height: 24px;
  background-position: -5px -243px;
}

.sprite-iq {
  width: 24px;
  height: 24px;
  background-position: -39px -243px;
}

.sprite-ir {
  width: 24px;
  height: 24px;
  background-position: -73px -243px;
}

.sprite-is {
  width: 24px;
  height: 24px;
  background-position: -107px -243px;
}

.sprite-it {
  width: 24px;
  height: 24px;
  background-position: -141px -243px;
}

.sprite-je {
  width: 24px;
  height: 24px;
  background-position: -175px -243px;
}

.sprite-jm {
  width: 24px;
  height: 24px;
  background-position: -209px -243px;
}

.sprite-jo {
  width: 24px;
  height: 24px;
  background-position: -243px -243px;
}

.sprite-jp {
  width: 24px;
  height: 24px;
  background-position: -277px -243px;
}

.sprite-ke {
  width: 24px;
  height: 24px;
  background-position: -311px -243px;
}

.sprite-kg {
  width: 24px;
  height: 24px;
  background-position: -345px -243px;
}

.sprite-kh {
  width: 24px;
  height: 24px;
  background-position: -379px -243px;
}

.sprite-ki {
  width: 24px;
  height: 24px;
  background-position: -413px -243px;
}

.sprite-km {
  width: 24px;
  height: 24px;
  background-position: -447px -243px;
}

.sprite-kn {
  width: 24px;
  height: 24px;
  background-position: -481px -243px;
}

.sprite-kosovo {
  width: 24px;
  height: 24px;
  background-position: -5px -277px;
}

.sprite-kp {
  width: 24px;
  height: 24px;
  background-position: -39px -277px;
}

.sprite-kr {
  width: 24px;
  height: 24px;
  background-position: -73px -277px;
}

.sprite-kw {
  width: 24px;
  height: 24px;
  background-position: -107px -277px;
}

.sprite-ky {
  width: 24px;
  height: 24px;
  background-position: -141px -277px;
}

.sprite-kz {
  width: 24px;
  height: 24px;
  background-position: -175px -277px;
}

.sprite-la {
  width: 24px;
  height: 24px;
  background-position: -209px -277px;
}

.sprite-lb {
  width: 24px;
  height: 24px;
  background-position: -243px -277px;
}

.sprite-lc {
  width: 24px;
  height: 24px;
  background-position: -277px -277px;
}

.sprite-li {
  width: 24px;
  height: 24px;
  background-position: -311px -277px;
}

.sprite-lk {
  width: 24px;
  height: 24px;
  background-position: -345px -277px;
}

.sprite-lr {
  width: 24px;
  height: 24px;
  background-position: -379px -277px;
}

.sprite-ls {
  width: 24px;
  height: 24px;
  background-position: -413px -277px;
}

.sprite-lt {
  width: 24px;
  height: 24px;
  background-position: -447px -277px;
}

.sprite-lu {
  width: 24px;
  height: 24px;
  background-position: -481px -277px;
}

.sprite-lv {
  width: 24px;
  height: 24px;
  background-position: -5px -311px;
}

.sprite-ly {
  width: 24px;
  height: 24px;
  background-position: -39px -311px;
}

.sprite-ma {
  width: 24px;
  height: 24px;
  background-position: -73px -311px;
}

.sprite-mc {
  width: 24px;
  height: 24px;
  background-position: -107px -311px;
}

.sprite-md {
  width: 24px;
  height: 24px;
  background-position: -141px -311px;
}

.sprite-me {
  width: 24px;
  height: 24px;
  background-position: -175px -311px;
}

.sprite-mg {
  width: 24px;
  height: 24px;
  background-position: -209px -311px;
}

.sprite-mh {
  width: 24px;
  height: 24px;
  background-position: -243px -311px;
}

.sprite-mk {
  width: 24px;
  height: 24px;
  background-position: -277px -311px;
}

.sprite-ml {
  width: 24px;
  height: 24px;
  background-position: -311px -311px;
}

.sprite-mm {
  width: 24px;
  height: 24px;
  background-position: -345px -311px;
}

.sprite-mn {
  width: 24px;
  height: 24px;
  background-position: -379px -311px;
}

.sprite-mo {
  width: 24px;
  height: 24px;
  background-position: -413px -311px;
}

.sprite-mp {
  width: 24px;
  height: 24px;
  background-position: -447px -311px;
}

.sprite-mq {
  width: 24px;
  height: 24px;
  background-position: -481px -311px;
}

.sprite-mr {
  width: 24px;
  height: 24px;
  background-position: -5px -345px;
}

.sprite-ms {
  width: 24px;
  height: 24px;
  background-position: -39px -345px;
}

.sprite-mt {
  width: 24px;
  height: 24px;
  background-position: -73px -345px;
}

.sprite-mu {
  width: 24px;
  height: 24px;
  background-position: -107px -345px;
}

.sprite-mv {
  width: 24px;
  height: 24px;
  background-position: -141px -345px;
}

.sprite-mw {
  width: 24px;
  height: 24px;
  background-position: -175px -345px;
}

.sprite-mx {
  width: 24px;
  height: 24px;
  background-position: -209px -345px;
}

.sprite-my {
  width: 24px;
  height: 24px;
  background-position: -243px -345px;
}

.sprite-mz {
  width: 24px;
  height: 24px;
  background-position: -277px -345px;
}

.sprite-na {
  width: 24px;
  height: 24px;
  background-position: -311px -345px;
}

.sprite-nc {
  width: 24px;
  height: 24px;
  background-position: -345px -345px;
}

.sprite-ne {
  width: 24px;
  height: 24px;
  background-position: -379px -345px;
}

.sprite-nf {
  width: 24px;
  height: 24px;
  background-position: -413px -345px;
}

.sprite-ng {
  width: 24px;
  height: 24px;
  background-position: -447px -345px;
}

.sprite-ni {
  width: 24px;
  height: 24px;
  background-position: -481px -345px;
}

.sprite-nl {
  width: 24px;
  height: 24px;
  background-position: -5px -379px;
}

.sprite-no {
  width: 24px;
  height: 24px;
  background-position: -39px -379px;
}

.sprite-northern_ireland {
  width: 24px;
  height: 24px;
  background-position: -73px -379px;
}

.sprite-np {
  width: 24px;
  height: 24px;
  background-position: -107px -379px;
}

.sprite-nr {
  width: 24px;
  height: 24px;
  background-position: -141px -379px;
}

.sprite-nu {
  width: 24px;
  height: 24px;
  background-position: -175px -379px;
}

.sprite-nz {
  width: 24px;
  height: 24px;
  background-position: -209px -379px;
}

.sprite-om {
  width: 24px;
  height: 24px;
  background-position: -243px -379px;
}

.sprite-pa {
  width: 24px;
  height: 24px;
  background-position: -277px -379px;
}

.sprite-pe {
  width: 24px;
  height: 24px;
  background-position: -311px -379px;
}

.sprite-pg {
  width: 24px;
  height: 24px;
  background-position: -345px -379px;
}

.sprite-ph {
  width: 24px;
  height: 24px;
  background-position: -379px -379px;
}

.sprite-pk {
  width: 24px;
  height: 24px;
  background-position: -413px -379px;
}

.sprite-pl {
  width: 24px;
  height: 24px;
  background-position: -447px -379px;
}

.sprite-pm {
  width: 24px;
  height: 24px;
  background-position: -481px -379px;
}

.sprite-pn {
  width: 24px;
  height: 24px;
  background-position: -5px -413px;
}

.sprite-pr {
  width: 24px;
  height: 24px;
  background-position: -39px -413px;
}

.sprite-ps {
  width: 24px;
  height: 24px;
  background-position: -73px -413px;
}

.sprite-pt {
  width: 24px;
  height: 24px;
  background-position: -107px -413px;
}

.sprite-pw {
  width: 24px;
  height: 24px;
  background-position: -141px -413px;
}

.sprite-py {
  width: 24px;
  height: 24px;
  background-position: -175px -413px;
}

.sprite-qa {
  width: 24px;
  height: 24px;
  background-position: -209px -413px;
}

.sprite-re {
  width: 24px;
  height: 24px;
  background-position: -243px -413px;
}

.sprite-ro {
  width: 24px;
  height: 24px;
  background-position: -277px -413px;
}

.sprite-ru {
  width: 24px;
  height: 24px;
  background-position: -311px -413px;
}

.sprite-rw {
  width: 24px;
  height: 24px;
  background-position: -345px -413px;
}

.sprite-sa {
  width: 24px;
  height: 24px;
  background-position: -379px -413px;
}

.sprite-sb {
  width: 24px;
  height: 24px;
  background-position: -413px -413px;
}

.sprite-sc {
  width: 24px;
  height: 24px;
  background-position: -447px -413px;
}

.sprite-scotland {
  width: 24px;
  height: 24px;
  background-position: -481px -413px;
}

.sprite-sd {
  width: 24px;
  height: 24px;
  background-position: -5px -447px;
}

.sprite-se {
  width: 24px;
  height: 24px;
  background-position: -39px -447px;
}

.sprite-sg {
  width: 24px;
  height: 24px;
  background-position: -73px -447px;
}

.sprite-sh {
  width: 24px;
  height: 24px;
  background-position: -107px -447px;
}

.sprite-si {
  width: 24px;
  height: 24px;
  background-position: -141px -447px;
}

.sprite-sint_eustatius {
  width: 24px;
  height: 24px;
  background-position: -175px -447px;
}

.sprite-sj {
  width: 24px;
  height: 24px;
  background-position: -209px -447px;
}

.sprite-sk {
  width: 24px;
  height: 24px;
  background-position: -243px -447px;
}

.sprite-sl {
  width: 24px;
  height: 24px;
  background-position: -277px -447px;
}

.sprite-sm {
  width: 24px;
  height: 24px;
  background-position: -311px -447px;
}

.sprite-sn {
  width: 24px;
  height: 24px;
  background-position: -345px -447px;
}

.sprite-so {
  width: 24px;
  height: 24px;
  background-position: -379px -447px;
}

.sprite-sr {
  width: 24px;
  height: 24px;
  background-position: -413px -447px;
}

.sprite-ss {
  width: 24px;
  height: 24px;
  background-position: -447px -447px;
}

.sprite-st {
  width: 24px;
  height: 24px;
  background-position: -481px -447px;
}

.sprite-sv {
  width: 24px;
  height: 24px;
  background-position: -5px -481px;
}

.sprite-sx {
  width: 24px;
  height: 24px;
  background-position: -39px -481px;
}

.sprite-sy {
  width: 24px;
  height: 24px;
  background-position: -73px -481px;
}

.sprite-sz {
  width: 24px;
  height: 24px;
  background-position: -107px -481px;
}

.sprite-tc {
  width: 24px;
  height: 24px;
  background-position: -141px -481px;
}

.sprite-td {
  width: 24px;
  height: 24px;
  background-position: -175px -481px;
}

.sprite-tf {
  width: 24px;
  height: 24px;
  background-position: -209px -481px;
}

.sprite-tg {
  width: 24px;
  height: 24px;
  background-position: -243px -481px;
}

.sprite-th {
  width: 24px;
  height: 24px;
  background-position: -277px -481px;
}

.sprite-tj {
  width: 24px;
  height: 24px;
  background-position: -311px -481px;
}

.sprite-tk {
  width: 24px;
  height: 24px;
  background-position: -345px -481px;
}

.sprite-tl {
  width: 24px;
  height: 24px;
  background-position: -379px -481px;
}

.sprite-tm {
  width: 24px;
  height: 24px;
  background-position: -413px -481px;
}

.sprite-tn {
  width: 24px;
  height: 24px;
  background-position: -447px -481px;
}

.sprite-tonga {
  width: 24px;
  height: 24px;
  background-position: -481px -481px;
}

.sprite-tp {
  width: 24px;
  height: 24px;
  background-position: -515px -5px;
}

.sprite-tr {
  width: 24px;
  height: 24px;
  background-position: -515px -39px;
}

.sprite-tt {
  width: 24px;
  height: 24px;
  background-position: -515px -73px;
}

.sprite-tv {
  width: 24px;
  height: 24px;
  background-position: -515px -107px;
}

.sprite-tw {
  width: 24px;
  height: 24px;
  background-position: -515px -141px;
}

.sprite-tz {
  width: 24px;
  height: 24px;
  background-position: -515px -175px;
}

.sprite-ua {
  width: 24px;
  height: 24px;
  background-position: -515px -209px;
}

.sprite-ug {
  width: 24px;
  height: 24px;
  background-position: -515px -243px;
}

.sprite-uk {
  width: 24px;
  height: 24px;
  background-position: -515px -277px;
}

.sprite-us {
  width: 24px;
  height: 24px;
  background-position: -515px -311px;
}

.sprite-uy {
  width: 24px;
  height: 24px;
  background-position: -515px -345px;
}

.sprite-uz {
  width: 24px;
  height: 24px;
  background-position: -515px -379px;
}

.sprite-va {
  width: 24px;
  height: 24px;
  background-position: -515px -413px;
}

.sprite-vc {
  width: 24px;
  height: 24px;
  background-position: -515px -447px;
}

.sprite-ve {
  width: 24px;
  height: 24px;
  background-position: -515px -481px;
}

.sprite-vg {
  width: 24px;
  height: 24px;
  background-position: -5px -515px;
}

.sprite-vi {
  width: 24px;
  height: 24px;
  background-position: -39px -515px;
}

.sprite-vn {
  width: 24px;
  height: 24px;
  background-position: -73px -515px;
}

.sprite-vu {
  width: 24px;
  height: 24px;
  background-position: -107px -515px;
}

.sprite-wales {
  width: 24px;
  height: 24px;
  background-position: -141px -515px;
}

.sprite-wf {
  width: 24px;
  height: 24px;
  background-position: -175px -515px;
}

.sprite-ws {
  width: 24px;
  height: 24px;
  background-position: -209px -515px;
}

.sprite-ye {
  width: 24px;
  height: 24px;
  background-position: -243px -515px;
}

.sprite-yt {
  width: 24px;
  height: 24px;
  background-position: -277px -515px;
}

.sprite-yu {
  width: 24px;
  height: 24px;
  background-position: -311px -515px;
}

.sprite-za {
  width: 24px;
  height: 24px;
  background-position: -345px -515px;
}

.sprite-zm {
  width: 24px;
  height: 24px;
  background-position: -379px -515px;
}

.sprite-zw {
  width: 24px;
  height: 24px;
  background-position: -413px -515px;
}
