.awards {
  padding-top: em(90, $fz);
  padding-bottom: em(30, $fz);
  background: #fff url('../img/awards-bg.png') center no-repeat;
  background-size: cover;

  &__slide {
    display: flex;
  }

  &__img {
    margin-right: em(30, $fz);
    max-width: em(115, $fz);
    max-height: em(115, $fz);
  }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: em(200, $fz);
  }

  &__name {
    font-size: em(15, $fz);
    font-weight: 700;
  }

  &__date {
    $fz: 12;
    font-size: em($fz, $fz_base);
    color: $text-color;
  }

  &__wrapper {
    margin-bottom: em(75, $fz);
  }

  .swiper-pagination-bullet {
    margin: 0 em(4, $fz);
  }
}

.awards__pagination {
  position: relative;
}

@media only screen and (max-width: $media-xl) {
  .awards__name {
    $fz: 13;
    font-size: em($fz, $fz_base);
  }

  .awards__date {
    $fz: 12;
    font-size: em($fz, $fz_base);
  }
}

@media only screen and (max-width: $media-l) {
  .awards {
    padding-top: em(80, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .awards {
    padding-top: em(60, $fz);
    padding-bottom: 0;
  }

  .awards__slide {
    justify-content: center;
  }

  .awards__pagination {
    display: none;
  }
}

@media only screen and (max-width: $media-s) {
}

@media only screen and (max-width: $media-xs) {
}

