.footer {
  position: relative;
  padding-top: em(40, $fz);

  &__home {
    //background: #fff url('../img/footer-bg.png') center no-repeat;
    background-color: $bg-color;
  }

  &__news {
    box-shadow: 1px em(-2, $fz) em(5, $fz) rgba(8, 11, 16, 0.1);
  }

  &__logo {
    $fz: 27;
    margin-bottom: em(54, $fz);
  }

  &__networks {

    .networks__item {
      margin-right: em(24, $fz);

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__navlist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__navlink {
    $fz: 14;
    font-size: em($fz, $fz_base);
    padding-left: em(21, $fz);
    position: relative;
    text-transform: uppercase;

    &:hover {
      color: $main-color;
    }

    &::before {
      content: url('../img/arrow.svg');
      position: absolute;
      left: 0;
      width: em(8, $fz);
    }
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__link {
    $fz: 14;
    font-size: em($fz, $fz_base);
    position: relative;
    padding-left: em(30, $fz);
    display: flex;
    align-items: center;
    margin-bottom: em(35, $fz);

    &:hover {
      color: $main-color;

      .contacts__svg {
        fill: $main-color-hover;
      }
    }
  }

  &__svg {
    $fz: 14;
    position: absolute;
    left: 0;
    fill: $main-color;
    width: em(15, $fz);
  }
}

.subs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__text {
    $fz: 14;
    font-size: em($fz, $fz_base);
  }

  &__form {
    display: flex;
  }

  &__input {
    $fz: 12;
    font-size: em($fz, $fz_base);
    padding: em(8, $fz) em(9, $fz) em(8, $fz) em(19, $fz);
    color: $text-color;
    border: none;
    background-color: #eeeeee;
    width: em(260, $fz);
  }

  &__btn {
    .btn__container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: em(10, $fz) em(16, $fz);
    }
  }

  &__svg {
    fill: #fff;
    width: em(14, $fz);
    height: em(10, $fz);
  }

  &__link {
    $fz: 14;
    font-size: em($fz, $fz_base);
    text-decoration: underline;
    color: #433462;

    &:hover {
      color: $main-color;
    }
  }
}

.copyright {
  margin-top: em(63, $fz);
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    $fz: 12;
    font-size: em($fz, $fz_base);
    color: $text-color;
    padding: em(21, $fz) 0;
  }
}

@media only screen and (max-width: $media-xl) {
}

@media only screen and (max-width: $media-l) {
}

@media only screen and (max-width: $media-m) {

  .footer__logo {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    $fz: 24;
    max-width: em(190, $fz);
  }

  .footer__wrapper {
    justify-content: space-evenly;
  }

  .contacts,
  .footer__navlist {
    margin-bottom: em(35, $fz);
    margin-right: em(15, $fz);
  }

  .subs__form {
    margin-bottom: em(30, $fz);
  }

  .subs__text,
  .subs__link {
    $fz: 14;
    margin-bottom: em(30, $fz);
  }

  .subs__link:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: $media-s) {
}

@media only screen and (max-width: $media-xs) {
  .footer {
    .container {
      overflow-x: hidden;
    }
  }

  .footer__navlist {
    display: none;
  }

  .footer__logo {
    $fz: 16.6;
    max-width: em(130, $fz);
    margin-bottom: em(35, $fz);
  }

  .contacts__link {
    $fz: 14;
    margin-bottom: em(30, $fz);
  }

  .contacts {
    width: 100%;
  }

  .footer__wrapper {
    justify-content: flex-start;
  }

  .footer__networks {
    background-color: $main-color;
    max-width: 120%;
    width: 120%;
    justify-content: center;
    padding: em(10, $fz) 0;
    margin-left: em(-20, $fz);
  }

  .footer__networks .networks__link {
    margin: 0 em(8, $fz);
  }

  .footer__networks .networks__svg {
    fill: #fff;
  }

  .subs__input {
    $fz: 12;
    width: em(225, $fz);
  }

  .subs__btn {
    .btn__container {
      padding: em(11, $fz) em(21, $fz);
    }
  }

  .subs__svg {
    width: em(19, $fz);
    height: em(14, $fz);
  }
}

