.mobile {
  padding-top: em(250, $fz);
  padding-bottom: em(145, $fz);
  background-color: $bg-color;

  &__group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: em(8, $fz) em(32, $fz) em(8, $fz) em(10, $fz);
    border-radius: em(33, $fz);
    position: relative;
    z-index: 2;
    transition: background-color .3s;
    background-color: transparent;

    &:hover {
      background-color: #7b37b9;

      .mobile__features {
        color: #fff;
      }

      .mobile__svg {
        fill: #0e6ad5;
      }

      .mobile__icon {
        background-color: #fff;
      }
    }

    &--reverse {
      flex-direction: row-reverse;
      padding: em(8, $fz) em(10, $fz) em(8, $fz) em(32, $fz);
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: transparent;
      z-index: 3;
    }
  }

  &__icon {
    width: em(56, $fz);
    height: em(56, $fz);
    border-radius: 50%;
    background-color: #7b37b9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: em(37, $fz);
    transition: background-color .3s;

    &--reverse {
      margin-right: 0;
      margin-left: em(37, $fz);
    }
  }

  &__svg {
    fill: #fff;
    transition: fill .3s;
    width: em(31, $fz);

    &--w30 {
      width: em(30, $fz);
    }

    &--h30 {
      height: em(30, $fz);
    }
  }

  &__features {
    $fz: 24;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    color: #1e1a24;
    transition: color .3s;
  }

  &__phone {
    position: relative;
    z-index: 1;

    picture {
      position: relative;
    }

    &.active::before {
      transform: translate(-50%, -50%) scale(1.67);
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: em(420, $fz);
      height: em(420, $fz);
      left: 50%;
      top: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      transition: transform .3s;
    }
  }

  &__download {
    display: flex;
    justify-content: space-between;
    margin: em(120, $fz) auto 0;
  }

  &__link {
    display: flex;
    align-items: center;
    border-radius: em(3, $fz);

    &:first-of-type {
      margin-right: em(130, $fz);
    }

    &:hover {
      box-shadow: 1px 0 em(7, $fz) rgba(8, 11, 16, 0.5);
    }
  }
}

@media only screen and (max-width: $media-xl) {
  .mobile {
    padding-top: em(150, $fz);
    padding-bottom: em(100, $fz);
  }
}

@media only screen and (max-width: $media-l) {
  .mobile {
    padding-top: em(100, $fz);
    padding-bottom: em(80, $fz);
  }

  .mobile__icon {
    margin-right: em(15, $fz);
  }

  .mobile__icon--reverse {
    margin-right: 0;
    margin-left: em(15, $fz);
  }

  .mobile__item--reverse {
    text-align: right;
  }

  .mobile__features {
    $fz: 24;
    font-size: em($fz, $fz_base);
    max-width: em(100, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .mobile__phone::before {
    display: none;
  }

  .mobile__group {
    flex-direction: column;
    align-items: center;
  }

  .mobile__sidebar {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: em(50, $fz);
  }

  .mobile__item {
    width: 50%;
    flex-direction: column;
    padding: em(5, $fz) 0;
    margin-bottom: em(30, $fz);
    text-align: center;
  }

  .mobile__icon {
    margin: 0 0 em(15, $fz);
  }

  .mobile__features {
    max-width: 100%;
  }

  .mobile__phone {
    margin-bottom: em(60, $fz);
  }

  .mobile__download {
    margin-top: 0;
  }

  .mobile__link {
    width: em(180, $fz);
  }
}

@media only screen and (max-width: $media-s) {
  .mobile__features {
    $fz: 18;
    font-size: em($fz, $fz_base);
  }

  .mobile__link:first-of-type {
    margin-right: em(15, $fz);
  }
}

@media only screen and (max-width: $media-xs) {
  .mobile__icon {
    $fz: 14;
    font-size: em($fz, $fz_base);
    width: em(45, $fz);
    height: em(45, $fz);
  }

  .mobile__link {
    width: em(137, $fz);
  }
}
