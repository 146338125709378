html,
body {
  height: 100%;
}

body {
  background: #fff;
  font-family: 'Roboto', sans-serif;
  $fz: 16;
  font-size: em($fz, $fz_base);
  color: #000;
  font-weight: 400;
  min-width: em(320, $fz);

  &.overflow {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

section {
  position: relative;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

br {
  font-size: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.container {
  max-width: em(1170, $fz);
  width: 100%;
  padding: 0 em(15, $fz);
  margin: 0 auto;
}

input {
  outline: none;
}

a {
  color: inherit;
}

.btn {
  color: #fff;
  background-color: $main-color;
  border: none;
  cursor: pointer;
  transition: background-color .2s;
  padding: 0;

  &__container {
    display: inline-block;
  }

  &__label {
    $fz: 20;
    font-size: em($fz, $fz_base);
    font-weight: 400;
    line-height: 1;
  }

  &:hover {
    background-color: $main-color-hover;
  }
}

picture {
  display: flex;
}


