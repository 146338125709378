.header {
  position: relative;
  background-color: #433462;
  height: em(45, $fz);

  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__contacts {
    display: flex;
    justify-content: flex-start;
  }

  &__link {
    $fz: 14;
    font-size: em($fz, $fz_base);
    color: #fff;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  &__divider {
    color: #fff;
    margin-left: em(20, $fz);
    margin-right: em(15, $fz);
  }

  &__svg {
    $fz: 14;
    margin-right: em(10, $fz);
    fill: #fff;
    height: em(11, $fz);
    width: em(15, $fz);
  }

  &__networks {
    .networks__item {
      margin-right: em(13, $fz);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.networks {
  display: flex;
  justify-content: flex-start;

  &__link {
    display: flex;
    align-items: center;
  }

  &__svg {
    &--white {
      fill: #fff;
    }

    &--purple {
      fill: $main-color;

      &:hover {
        fill: $main-color-hover;
      }
    }

    &--size17 {
      width: em(17, $fz);
      height: em(17, $fz);
    }

    &--size19 {
      width: em(19, $fz);
      height: em(19, $fz);
    }

    &--size25 {
      width: em(25, $fz);
      height: em(25, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .header {
    display: none;
  }
}
