.choice {
  padding-top: em(120, $fz);
  padding-bottom: em(120, $fz);
  background-color: $bg-color;

  &__group {
    display: flex;
  }

  &__swiper {
    width: 100%;
    max-width: 80%;
  }

  &__btn {
    box-shadow: 1px 0 em(5, $fz) rgba(8, 11, 16, 0.15);
    display: none;
    margin: 0 auto em(20, $fz);
    background-color: transparent;
    border: none;
    padding: 0;

    &:active {
      box-shadow: 1px 0 em(10, $fz) rgba(8, 11, 16, 0.3);
    }

    .btn__container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: em(9, $fz) em(48, $fz);
    }

    .btn__label {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }
  }

  &__svg {
    margin-right: em(10, $fz);
    fill: $main-color;
    width: em(19, $fz);
    height: em(19, $fz);
  }

  .swiper-pagination {
    bottom: 0;
    left: 0;
    width: 100%;

    &-bullet {
      margin: 0 em(4, $fz);
    }
  }

  .swiper-wrapper {
    padding-bottom: em(15, $fz);
  }
}

.section__title {
  $fz: 32;
  font-size: em($fz, $fz_base);
  margin-bottom: em(70, $fz);
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  line-height: 1;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: em(50, $fz);
  max-width: em(245, $fz);
  width: 100%;
  position: relative;

  &__type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: em(35, $fz);
    width: 100%;
  }

  &__title {
    $fz: 21;
    font-size: em($fz, $fz_base);
    font-weight: 400;
    margin-bottom: em(20, $fz);
  }
}

.filter {
  width: em(245, $fz);
  box-shadow: 1px 0 em(5, $fz) rgba(8, 11, 16, 0.15);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &.active {
    .filter__wrapper {
      display: block;
      box-shadow: 1px 0 em(5, $fz) rgba(8, 11, 16, 0.15);
    }

    .filter__header {
      background-color: #fff;
    }
  }

  &__current {
    $fz: 18;
    font-size: em($fz, $fz_base);
  }

  &[data-type='currency'] {
    text-transform: uppercase;
  }

  &__header {
    position: relative;
    padding: em(12, $fz) em(15, $fz) em(10, $fz);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    flex-direction: column;
    display: flex;
    max-height: em(200, $fz);
    overflow-y: auto;
  }

  &__wrapper {
    display: none;
    top: calc(100% + 1px);
    background-color: #fff;
    width: 100%;
    border-radius: em(3, $fz);
    position: absolute;
    z-index: 5;
  }

  &__item {
    $fz: 18;
    font-size: em($fz, $fz_base);
    padding: em(9, $fz) em(15, $fz);
    line-height: 1;
    cursor: pointer;
    border-bottom: .5px solid transparent;
    border-top: .5px solid transparent;
    background-clip: padding-box;

    &:hover {
      color: #fff;
      background-color: #0e6ad5;
    }
  }

  &__svg {
    fill: #808080;
    width: em(11, $fz);
    height: em(8, $fz);
  }
}

.range {
  margin-right: em(30, $fz);
  margin-bottom: em(50, $fz);
  flex-basis: 65%;
  width: 100%;

  &__value {
    $fz: 18;
    font-size: em($fz, $fz_base);
    margin-bottom: em(15, $fz);
    margin-left: em(35, $fz);
  }

  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__borders {
    $fz: 13;
    font-size: em($fz, $fz_base);
    margin-top: em(8, $fz);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__item {
    -webkit-appearance: none;
    width: 100%;
    padding: 0;
    position: relative;
    cursor: pointer;
    background-color: $bg-color;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: em(20, $fz);
      height: em(20, $fz);
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      margin-top: em(-8, $fz);
      box-shadow: 0 0 em(5, $fz) rgba(8, 11, 16, 0.41);
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: em(5, $fz);
      cursor: pointer;
      background: #0e6ad5;
    }

    &::-moz-range-thumb {
      width: em(20, $fz);
      height: em(20, $fz);
      border-radius: 50%;
      box-shadow: 0 0 em(5, $fz) rgba(8, 11, 16, 0.41);
      background: #fff;
      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      height: em(5, $fz);
      cursor: pointer;
      background: #0e6ad5;
    }

    &::-ms-track {
      margin-top: em(-10, $fz);
      width: 100%;
      height: em(5, $fz);
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
      padding: em(12, $fz) 0;
    }

    &::-ms-fill-lower {
      background: #0e6ad5;
      cursor: pointer;
    }

    &::-ms-fill-upper {
      background: #0e6ad5;
      cursor: pointer;
    }

    &::-ms-thumb {
      margin-top: 0;
      width: em(20, $fz);
      height: em(20, $fz);
      box-shadow: 0 0 em(5, $fz) rgba(8, 11, 16, 0.41);
      border-radius: 50%;
    }
  }
}

.radio {
  margin-bottom: em(-15, $fz);

  &__item {
    margin-bottom: em(15, $fz);
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: em(10, $fz);
  }
}

.offer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: em(392, $fz);
  background-color: #fff;
  box-shadow: 1px 0 em(5, $fz) rgba(8, 11, 16, 0.15);

  &:hover {
    box-shadow: 1px 0 em(10, $fz) rgba(8, 11, 16, 0.4);
  }

  &__header {
    background-color: #483f6c;
    color: #fff;
    padding: em(5, $fz);
    width: 100%;
    line-height: 1;
    min-height: em(46, $fz);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: em(35, $fz) em(27, $fz) em(45, $fz);
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__stats {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-bottom: em(40, $fz);
  }

  &__stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__scale {
    width: em(80, $fz);
    display: flex;
    justify-content: space-between;
    margin-bottom: em(10, $fz);
  }

  &__round {
    height: em(13, $fz);
    width: em(13, $fz);
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    background-color: #463b6a;

    &--empty {
      background-color: #fff;
    }
  }

  &__percentage {
    $fz: 49;
    font-size: em($fz, $fz_base);
    text-align: center;
    width: 100%;
    margin-top: em(35, $fz);
  }

  &__desc {
    $fz: 12;
    font-size: em($fz, $fz_base);
    line-height: 1.5;
    text-align: justify;
    margin-bottom: em(45, $fz);
    max-height: em(90, $fz);
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, .82) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__btn {
    .btn__container {
      padding: em(12, $fz) em(40, $fz);
    }
  }
}

.swiper-slide {
  height: auto;
}

.swiper-pagination-bullet-active {
  background-color: $main-color;
}

@media only screen and (max-width: $media-xl) {
  .choice {
    padding-top: em(80, $fz);
    padding-bottom: em(60, $fz);
  }
}

@media only screen and (max-width: $media-l) {
  .section__title {
    $fz: 28;
    font-size: em($fz, $fz_base);
  }

  .choice {
    padding-top: em(60, $fz);
    padding-bottom: em(50, $fz);
  }

  .options {
    margin-right: em(15, $fz);
  }

  .offer {
    &__stats,
    &__desc {
      margin-bottom: em(20, $fz);
    }
  }

  .offer__percentage {
    $fz: 36;
    font-size: em($fz, $fz_base);
    margin-top: em(20, $fz);
  }

  .offer__scale {
    width: em(72, $fz);
  }

  .offer__round {
    width: em(10, $fz);
    height: em(10, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .section__title {
    $fz: 24;
    font-size: em($fz, $fz_base);
    margin-bottom: em(50, $fz);
    text-align: center;
  }

  .choice {
    padding-top: em(50, $fz);
    padding-bottom: em(50, $fz);

    .swiper-wrapper {
      padding-bottom: em(30, $fz);
    }
  }

  .choice__swiper {
    max-width: 100%;
  }

  .choice__btn {
    display: block;
  }

  .choice__group {
    flex-direction: column;
  }

  .options {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    justify-content: space-evenly;
    display: none;

    &.active {
      display: flex;
    }
  }

  .options__type {
    margin-bottom: em(20, $fz);
  }

  .options__dropdown,
  .options__radio {
    width: 40%;
  }

  .options__radio {
    order: 2;
  }

  .options__range {
    max-width: em(500, $fz);
    width: 100%;
    order: 1;
  }

  .filter.select {
    max-width: em(245, $fz);
    width: 100%;
  }

  .range {
    margin-bottom: em(10, $fz);
  }

  .offer__btn {

    .btn__container {
      padding: em(9, $fz) em(40, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .section__title {
    $fz: 20;
    font-size: em($fz, $fz_base);
  }

  .choice {
    padding-top: em(40, $fz);
    padding-bottom: em(40, $fz);
  }

  .offer__percentage {
    $fz: 24;
    font-size: em($fz, $fz_base);
    color: #015c9f;
    position: absolute;
    right: 0;
    top: 50%;
    width: auto;
    margin: 0;
    transform: translateY(-50%);
  }

  .offer__stats {
    flex-direction: column;
    position: relative;
  }

  .offer__scale {
    margin-bottom: 0;
  }

  .offer__stat {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: em(10, $fz);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .offer__info {
    padding: em(33, $fz) em(15, $fz) em(40, $fz);
  }

  .offer__name {
    width: em(45, $fz);
  }
}

@media only screen and (max-width: $media-xs) {
  .choice {
    padding-top: em(30, $fz);
    padding-bottom: em(30, $fz);
  }

  .section__title {
    $fz: 16;
    font-size: em($fz, $fz_base);
    margin-bottom: em(40, $fz);
  }
}


