.head {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__home {
    background: url('../img/head_bg.jpg') center no-repeat;
    background-size: cover;
    min-height: calc(100vh - 45px);
    justify-content: space-between;
  }

  &__news {
    background: url('../img/head_bg-news.jpg') center no-repeat;
    min-height: em(400, $fz);
  }

  &__center {
    padding-top: em(20, $fz);
    padding-bottom: em(20, $fz);
  }

  &__title {
    $fz: 40;
    font-size: em($fz, $fz_base);
    max-width: em(600, $fz);
    text-transform: uppercase;
    line-height: 1.3;
    text-align: left;
    margin-bottom: em(40, $fz);
  }

  &__subtitle {
    $fz: 20;
    font-size: em($fz, $fz_base);
    margin-bottom: em(75, $fz);
    color: $text-color;
  }

  &__btn {
    .btn__container {
      padding: em(15, $fz) em(47, $fz);
    }

    .btn__label {
      font-weight: 700;
    }
  }

  &__phone {
    display: none;
  }

  &__svg {
    fill: $main-color;
    width: em(16, $fz);
    height: em(18, $fz);
  }

  &__picture {
    display: none;
  }
}

.logo {
  $fz: 27;
  font-size: em($fz, $fz_base);
  font-family: 'SecularOne', serif;
  text-transform: uppercase;
  color: #000;
  background: url('../img/logo.svg') 39% 50% no-repeat;
  background-size: contain;
  padding: em(18, $fz) em(2, $fz) 0;

  &__name {
    margin-right: em(41, $fz);
  }
}

.navbar {
  background-color: rgba(255, 255, 255, 0.75);
  height: em(85, $fz);

  &__wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  &__menu {
    display: flex;
    height: 100%;
    align-items: center;

    &.active {
      display: flex;
      position: absolute;
      right: 0;
      top: 100%;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.95);
      max-width: em(576, $fz);
      width: 100%;
      height: auto;

      .navbar__list {
        flex-direction: column;
        max-width: 100%;
      }

      .navbar__item {
        width: 100%;
        height: 100%;
      }

      .navbar__link {
        width: 100%;
        height: 100%;
        padding: em(15, $fz);
        justify-content: center;

        &:hover {
          background-color: #fff;
        }
      }

      &::before {
        content: '';
        position: fixed;
        top: em(115, $fz);
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-right: em(220, $fz);
  }

  &__item {
    height: 100%;
    margin-right: em(20, $fz);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    transition: color .3s;
    padding: 0 em(10, $fz);
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      color: #0e6ad5;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: em(2, $fz);
        width: 100%;
        background-color: #0e6ad5;
      }
    }
  }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: em(36, $fz);
    width: em(40, $fz);
    cursor: pointer;
    padding: em(5, $fz);
    transition: transform .1s;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &.active {
      transform: rotate(-90deg);
    }
  }
}

.burger-line {
  height: em(3, $fz);
  width: 100%;
  background-color: $main-color;
}

.lang {
  position: relative;
  min-width: em(80, $fz);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &.active {
    .lang__wrapper {
      display: block;
    }
  }

  &__wrapper {
    display: none;
    top: 100%;
    right: 0;
    width: em(365, $fz);
    background-color: rgba(39, 39, 51, .9);
    border-radius: em(3, $fz);
    position: absolute;
  }

  &__svg {
    transform: rotate(90deg);
    margin-left: em(15, $fz);
    width: em(10, $fz);
    height: em(10, $fz);
  }

  &__header {
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__current {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    $fz: 14;
    font-size: em($fz, $fz_base);
    padding: em(5, $fz) em(10, $fz);
    line-height: 1;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    min-width: em(73, $fz);
    width: 20%;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &:hover {
      color: #42a9ed;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.services {
  width: 100%;
  height: em(55, $fz);
  background-color: rgba(255, 255, 255, 0.75);

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__name {
    $fz: 18;
    font-size: em($fz, $fz_base);
    text-transform: uppercase;
    color: #2d1e4e;
    margin-left: em(10, $fz);
  }

  &__svg {
    fill: #473D6B;
    height: em(23, $fz);
    width: em(22, $fz);
  }
}

.breadcrumb {
  margin-top: em(25, $fz);
  margin-bottom: auto;

  &__item {
    $fz: 12;
    font-size: em($fz, $fz_base);
    display: inline;
    margin-right: em(15, $fz);
    color: #2d1e4e;
    text-transform: uppercase;

    &:hover {
      color: $main-color;
    }
  }

  &__link {
    $fz: 12;
    padding-right: em(7, $fz);
    position: relative;

    &::after {
      content: url('../img/arrow.svg');
      position: absolute;
      width: em(7, $fz);
      left: 100%;
    }
  }
}

.picture {
  &__img {
    object-fit: cover;
  }
}

.select__animation {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@media only screen and (max-width: $media-xl) {
}

@media only screen and (max-width: $media-l) {
  .navbar__list {
    margin-right: em(80, $fz);
  }

  .navbar__item {
    margin-right: em(10, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .navbar {
    height: em(70, $fz);
    z-index: 5;
  }

  .head__picture {
    position: relative;
    display: flex;
    z-index: 3;
    margin-top: em(-70, $fz);
  }

  .head__home {
    background-image: none;
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
  }

  .head__center {
    position: relative;
    padding: em(35, $fz) em(15, $fz);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: em(-70, $fz);
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: url('../img/noise_bg.jpg') center no-repeat;
      background-size: cover;
      z-index: -2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: -1;
    }
  }

  .head__subtitle {
    $fz: 20;
    margin-bottom: em(40, $fz);
  }

  .services {
    display: none;
  }


  .logo {
    $fz: 24;
    font-size: em($fz, $fz_base);
    padding: em(19, $fz) em(2, $fz) 0;
  }

  .navbar__menu {
    display: none;
  }

  .navbar__burger {
    display: flex;
  }

  .head__title {
    $fz: 28;
    font-size: em($fz, $fz_base);
    text-align: center;
  }

  .head__btn {
    .btn__container {
      padding: em(13, $fz) em(42, $fz);
    }
  }

  .btn__label {
    $fz: 16;
    font-size: em($fz, $fz_base);
  }

  .lang {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: em(5, $fz) 0;
  }

  .lang__wrapper {
    width: 100%;
  }

  .lang__list {
    justify-content: space-around;
  }

  .lang__item {
    width: auto;
  }

  .navbar__menu,
  .navbar__list,
  .navbar__item {
    width: 100%;
  }

  .navbar__item,
  .navbar__list {
    margin-right: 0;
  }
}

@media only screen and (max-width: $media-s) {

  .head__phone {
    display: flex;
    align-items: center;
  }

  .head__subtitle {
    display: none;
  }

  .head__title {
    $fz: 20;
    font-size: em($fz, $fz_base);
  }

  .navbar__menu.active::before {
    top: em(70, $fz);
  }
}

@media only screen and (max-width: $media-xs) {

  .head__news {
    min-height: em(320, $fz);
  }

  .navbar {
    height: em(50, $fz);

    &__burger {
      height: em(32, $fz);
      width: em(32, $fz);
    }
  }

  .head__center {
    margin-top: em(-40, $fz);
  }

  .head__picture {
    margin-top: em(-50, $fz);
  }

  .logo {
    $fz: 16.6;
    font-size: em($fz, $fz_base);
    padding: em(13, $fz) em(2, $fz) em(2, $fz);

    &__name {
      margin-right: em(26, $fz);
    }
  }

  .head__title {
    $fz: 17;
    font-size: em($fz, $fz_base);
  }

  .head__btn {
    .btn__container {
      max-width: em(160, $fz);
      padding: em(9, $fz) em(33, $fz);
    }
  }

  .btn__label {
    $fz: 14;
    font-size: em($fz, $fz_base);
  }

  .navbar__menu.active::before {
    top: em(50, $fz);
  }
}



