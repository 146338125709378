@font-face {
  font-family: "Roboto";
  src: url('../fonts/Roboto-Medium.ttf') format("truetype"),
  url('../fonts/Roboto-Medium.woff') format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url('../fonts/Roboto-Regular.ttf') format("truetype"),
  url('../fonts/Roboto-Regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url('../fonts/Roboto-Bold.ttf') format("truetype"),
  url('../fonts/Roboto-Bold.woff') format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SecularOne";
  src: url('../fonts/SecularOne-Regular.ttf') format("truetype"),
  url('../fonts/SecularOne-Regular.woff') format("woff");
  font-weight: 800;
  font-style: normal;
}