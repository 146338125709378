.news {
  padding-top: em(105, $fz);
  padding-bottom: em(80, $fz);
  background-color: #fff;

  .swiper-wrapper {
    padding: 0 em(3, $fz) em(3, $fz);
  }

  &__home {
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: em(210, $fz);
      border-radius: 50%;
      background-color: #fff;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &__item {
    max-width: em(360, $fz);
    position: relative;
    z-index: 2;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      display: none;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 em(67, $fz) em(56, $fz);
      border-color: transparent transparent $main-color transparent;
      z-index: 3;
    }

    &::before {
      content: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAb0lEQVQokZXSQQqAIBCF4ddKwvPVPbpqqzYdolbV5g9DQXQEEwZdfOj4VECoKc5mJXAAO+BbSBE8LZgWPoMb4CyUwwtYc1ie7yxo3aaEGj5Zj1HSKemWtHTt9LsnE1g5VcBKvAoyobn37QK0fwHoBduYniTxE39fAAAAAElFTkSuQmCC');
      position: absolute;
      z-index: 5;
      width: em(10, $fz);
      height: em(15, $fz);
      bottom: em(13, $fz);
      right: em(10, $fz);
    }

    &:hover {
      &::after {
        display: inline-block;
      }

      .news__desc {
        background-color: #fff;

        &::after {
          box-shadow: 1px 0 em(5, $fz) rgba(8, 11, 16, 0.15);
          background: none;
        }
      }
    }
  }

  &__desc {
    background-color: $bg-color;
    padding: em(40, $fz) em(27, $fz) em(65, $fz);
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: em(245, $fz);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, .82) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__title {
    $fz: 16;
    font-size: em($fz, $fz_base);
    text-transform: uppercase;
    margin-bottom: em(30, $fz);
    line-height: 1;
    color: #000;

    &--compressed {
      letter-spacing: -0.4px;
    }
  }

  &__text {
    $fz: 13;
    font-size: em($fz, $fz_base);
    color: $text-color;
    line-height: 1.5;
    max-height: em(92, $fz);
    overflow: hidden;
  }

  &__btn {
    display: none;
  }

  &__swiper {
    position: relative;
    z-index: 3;
  }
}

.news__prev,
.news__next {
  top: 27%;
  position: absolute;
  margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news__prev {
  left: em(10, $fz);
  right: auto;
}

.news__next {
  right: em(10, $fz);
  left: auto;
}

.news__arrow--left,
.news__arrow--right {
  fill: #fff;
  width: em(37, $fz);
  height: em(37, $fz);
}

.news__arrow--left {
  transform: rotate(180deg);
}

@media only screen and (max-width: $media-xl) {
  .news {
    padding: em(80, $fz) 0;
  }
}

@media only screen and (max-width: $media-l) {
  .news {
    padding: em(60, $fz) 0;
  }

  .news__home::after {
    display: none;
  }
}

@media only screen and (max-width: $media-m) {
  .news {
    padding: em(50, $fz) 0;
  }

  .news__desc {
    padding-bottom: em(45, $fz);
  }

  .news__btn {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: em(157, $fz);
    padding: em(11, $fz);
    line-height: 1;
  }
}

@media only screen and (max-width: $media-s) {
  .news {
    padding: em(40, $fz) 0;
  }

  .news__desc {
    padding: em(35, $fz) em(25, $fz) em(40, $fz);
  }

  .news__item {
    margin: 0 auto;
  }

  .news__prev,
  .news__next {
    display: none;
  }
}

@media only screen and (max-width: $media-xs) {
  .news {
    padding: em(30, $fz) 0;
  }

  .news__desc {
    padding: em(27, $fz) em(12, $fz) em(35, $fz);
  }

  .news__btn {
    $fz: 14;
    font-size: em($fz, $fz_base);
  }
}

